import React from 'react';
import { graphql, Link } from 'gatsby';
import Sign from '../components/sign'
import Layout from "../components/layout"
import {
  Section,
  Column,
  Columns,
} from "bloomer"  

export default ({ data }) => {
  return (
    <Layout>
      <Columns isMultiline isMobile className="column">
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Column isSize='1/3'>
          <Link to={node.fields.slug}>
            <Sign icon={node.frontmatter.mname} title={node.frontmatter.name}></Sign>
            <h1>{node.frontmatter.name}</h1>
          </Link>
          </Column>
      ))}
      </Columns>
    </Layout> 
  );
};

export const query = graphql`
  query horoscopoListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "horoscopo" } }
      }
      sort: { fields: [frontmatter___index], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            mname            
          }
          excerpt
        }
      }
    }
  }
`;